import { Box } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { endOfYesterday } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import { UiDateFilter } from './DateFilter.style';

export const DateFilter = ({
  startAt,
  endAt,
  onChangeStartAt,
  onChangeEndAt,
}) => {
  return (
    <UiDateFilter>
      <Box>
        <KeyboardDatePicker
          fullWidth
          ampm={false}
          cancelLabel='取消'
          format='MM/dd/yyyy'
          inputVariant="outlined"
          label='起始日期'
          okLabel='確定'
          size='small'
          maxDate={endOfYesterday()}
          value={startAt}
          onChange={onChangeStartAt}
        />
      </Box>
      <Box>
        <KeyboardDatePicker
          fullWidth
          ampm={false}
          cancelLabel='取消'
          format='MM/dd/yyyy'
          inputVariant="outlined"
          label='結束日期'
          okLabel='確定'
          size='small'
          minDate={startAt}
          maxDate={endOfYesterday()}
          value={endAt}
          onChange={onChangeEndAt}
        />
      </Box>
    </UiDateFilter>
  );
};

DateFilter.propTypes = {
  startAt: PropTypes.string,
  endAt: PropTypes.string,
  onChangeStartAt: PropTypes.func,
  onChangeEndAt: PropTypes.func,
};